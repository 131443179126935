
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import ApiService2 from "@/core/services/ApiService2";
import Services from "@/core/services/ArrService";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

interface CfitQuestion {  
  QuestionID: number;
  SerialNumber: number;
  QuestionNumber	:  number;
  QuestionImage	:  string;
  QuestionDescription : string;
  QuestionKey: string;
  QuestionOptions: string;
  TypeTestID: string;
  Times: number;
  MaxAnswersOpt: string;
  Group: string;
}

export default defineComponent({
  name: "create-cfit-question-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    cfitgroupid: String,
    cfitgrouptype: String,
    isopenmodal:Boolean,
    isupdate: Boolean,
    itemsupdate: {
        type: Object,
        default: () => ({}),
    },
  },
  setup(props, {emit}) {
    const submitButton = ref<HTMLElement | null>(null);
    const { cfitgroupid, isopenmodal } = toRefs(props);
    var cfitQuestion = ref<CfitQuestion>({
      QuestionID: 0,
      SerialNumber: 0,
      QuestionNumber:  0,
      QuestionImage:  "",
      QuestionDescription : "",
      QuestionKey: "",
      QuestionOptions: "",
      TypeTestID: "",
      Times: 0,
      MaxAnswersOpt: "",
      Group: "",
    });

    let fcorrectanswer = ref(false)
    let dialogAddQuestion = ref(false)
    var avatarFile = ref<File | null>(null); // Variable to hold the avatar file
    var avatarImageUrl = ref('media/avatars/blank.png'); // Default image

    const handleFileUpload = (event: Event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
          var file = input.files[0];
          avatarFile.value = file; // Save the file

          // Create a URL for the selected file
          avatarImageUrl.value = URL.createObjectURL(avatarFile.value)
        }
    };

    const removeImage = () => {
      avatarFile.value = null
      avatarImageUrl.value = "media/avatars/blank.png";
    };

    const cfitValidator = Yup.object().shape({
      fno: Yup.string().required().label("No."),
      fmax: Yup.string().required().label("Maksimal List Jawaban"),
      fkey: Yup.string().required().label("Kunci Jawaban"),
    });

    watch(isopenmodal, async (newVal, oldVal) => {
      cfitQuestion.value.QuestionID= 0
      cfitQuestion.value.SerialNumber= 0
      cfitQuestion.value.QuestionNumber= 0
      cfitQuestion.value.QuestionImage=  ""
      cfitQuestion.value.QuestionDescription = ""
      cfitQuestion.value.QuestionKey=""
      cfitQuestion.value.QuestionOptions= "1"
      cfitQuestion.value.TypeTestID = props.cfitgrouptype || ""
      cfitQuestion.value.Times= 0
      cfitQuestion.value.MaxAnswersOpt= ""
      cfitQuestion.value.Group= props.cfitgroupid || ""

      removeImage()

      if(props.isupdate){
        cfitQuestion.value.QuestionID = props.itemsupdate.QuestionID
        cfitQuestion.value.SerialNumber= props.itemsupdate.SerialNumber
        cfitQuestion.value.QuestionNumber	=  props.itemsupdate.QuestionNumber
        cfitQuestion.value.QuestionImage	=  props.itemsupdate.QuestionImage
        cfitQuestion.value.QuestionDescription = props.itemsupdate.QuestionDescription
        cfitQuestion.value.QuestionKey= props.itemsupdate.QuestionKey
        cfitQuestion.value.QuestionOptions= props.itemsupdate.QuestionOptions
        cfitQuestion.value.TypeTestID= props.itemsupdate.TypeTestID
        cfitQuestion.value.Times= props.itemsupdate.Times
        cfitQuestion.value.MaxAnswersOpt= props.itemsupdate.MaxAnswersOpt
        cfitQuestion.value.Group= props.itemsupdate.Group

        avatarImageUrl.value = new URL(props.itemsupdate.QuestionImage, process.env.VUE_APP_API_URL_REAL2).href
        avatarFile.value = await convertUrlToFile(avatarImageUrl.value, props.itemsupdate.QuestionImage)
      }

      if(newVal){
        dialogAddQuestion.value = true
      }
    });

    async function convertUrlToFile(imageUrl, fileName) {
        // Step 1: Fetch the image data
        const response = await fetch(imageUrl, { mode: 'no-cors' });
        const blob = await response.blob(); // Step 2: Create a Blob

        // Step 3: Create a File from the Blob
        var file = new File([blob], fileName, { type: blob.type });
        return file;
    }

    async function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)
      if(avatarFile.value != null)
        saveObj['QuestionImage'] = avatarFile.value

      saveObj['MaxAnswersOpt'] = saveObj['MaxAnswersOpt'].toUpperCase()
      saveObj['QuestionKey'] = saveObj['QuestionKey'].toUpperCase()
      console.log("saveObj", saveObj)

      if(props.cfitgroupid == "" || props.cfitgroupid == null || props.cfitgroupid == undefined){
         toast("Save Gagal, Silahkan pilih group dahulu", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        return cancelModal()
      }

      if(props.isupdate){
        update(saveObj)
      }else{
        save(saveObj)
      }
    };

    async function update(saveObj){
      var responseCache = []
      await Services.PutData(ApiService2,  `master/questions/${saveObj.QuestionID}`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", props.cfitgroupid);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    async function save(saveObj){
      var responseCache = []
      await Services.PostData(ApiService2, `master/questions/`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", props.cfitgroupid);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    const saveCfit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(cfitQuestion)
      }
    };

    function modalClose(){
      dialogAddQuestion.value = false
      emit("modalClose", true);
    }

    function cancelModal(){
      cfitQuestion.value.QuestionID= 0
      cfitQuestion.value.SerialNumber= 0
      cfitQuestion.value.QuestionNumber= 0
      cfitQuestion.value.QuestionImage=  ""
      cfitQuestion.value.QuestionDescription = ""
      cfitQuestion.value.QuestionKey=""
      cfitQuestion.value.QuestionOptions= "1"
      cfitQuestion.value.TypeTestID = props.cfitgrouptype || ""
      cfitQuestion.value.Times= 0
      cfitQuestion.value.MaxAnswersOpt= ""
      cfitQuestion.value.Group= props.cfitgroupid || ""

      removeImage()

      modalClose()
    }

    return {
      cfitQuestion,
      cfitValidator,
      saveCfit,
      submitButton,
      fcorrectanswer,
      dialogAddQuestion,
      cancelModal,
      modalClose,
      removeImage,
      avatarFile,
      handleFileUpload,
      avatarImageUrl,
      convertUrlToFile,
    };
  },
});
