import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CfitGroupComponent = _resolveComponent("CfitGroupComponent")!
  const _component_CfitQuestionComponent = _resolveComponent("CfitQuestionComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CfitGroupComponent, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        TypeTestID: _ctx.TypeTestID,
        onClickDraw: _ctx.itemCfit
      }, null, 8, ["TypeTestID", "onClickDraw"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CfitQuestionComponent, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        "cfit-group-id": _ctx.Cfit_Group_Id,
        "cfit-group-type": _ctx.Cfit_Group_Type
      }, null, 8, ["cfit-group-id", "cfit-group-type"])
    ])
  ]))
}