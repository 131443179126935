
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import ApiService2 from "@/core/services/ApiService2";
import Services from "@/core/services/ArrService";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

interface CfitGroup {  
  QuestionID: number;
  SerialNumber: number;
  QuestionNumber	:  number;
  QuestionImage	:  string;
  QuestionDescription : string;
  QuestionKey: string;
  QuestionOptions: string;
  TypeTestID: string;
  Times: number;
  MaxAnswersOpt: string;
  Group: string;
}

export default defineComponent({
  name: "create-cfit-group-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    isopenmodal:Boolean,
    isupdate: Boolean,
    itemsupdate: {
        type: Object,
        default: () => ({}),
    },
    typetestid: String,
  },
  setup(props, {emit}) {
    const { isopenmodal, itemsupdate } = toRefs(props);

    const submitButton = ref<HTMLElement | null>(null);
    var cfitGroup = ref<CfitGroup>({
      QuestionID: 0,
      SerialNumber: 0,
      QuestionNumber:  0,
      QuestionImage:  "",
      QuestionDescription : "",
      QuestionKey: "",
      QuestionOptions: "",
      TypeTestID: "",
      Times: 0,
      MaxAnswersOpt: "",
      Group: "",
    });
    let dialogAddGroup = ref(false)
    var avatarFile = ref<File | null>(null); // Variable to hold the avatar file
    var avatarImageUrl = ref('media/avatars/blank.png'); // Default image

    const handleFileUpload = (event: Event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
          var file = input.files[0];
          avatarFile.value = file; // Save the file

          // Create a URL for the selected file
          avatarImageUrl.value = URL.createObjectURL(avatarFile.value)
        }
    };

    const removeImage = () => {
      avatarFile.value = null
      avatarImageUrl.value = "media/avatars/blank.png";
    };

    watch(isopenmodal, async (newVal, oldVal) => {
      cfitGroup.value.QuestionID= 0
      cfitGroup.value.SerialNumber= 0
      cfitGroup.value.QuestionNumber= 0
      cfitGroup.value.QuestionImage=  ""
      cfitGroup.value.QuestionDescription = ""
      cfitGroup.value.QuestionKey=""
      cfitGroup.value.QuestionOptions= "2"
      cfitGroup.value.TypeTestID = props.typetestid || ""
      cfitGroup.value.Times= 0
      cfitGroup.value.MaxAnswersOpt= ""
      cfitGroup.value.Group= ""
      removeImage()

      if(props.isupdate){
        cfitGroup.value.QuestionID = props.itemsupdate.QuestionID
        cfitGroup.value.SerialNumber= props.itemsupdate.SerialNumber
        cfitGroup.value.QuestionNumber	=  props.itemsupdate.QuestionNumber
        cfitGroup.value.QuestionImage	=  props.itemsupdate.QuestionImage
        cfitGroup.value.QuestionDescription = props.itemsupdate.QuestionDescription
        cfitGroup.value.QuestionKey= props.itemsupdate.QuestionKey
        cfitGroup.value.QuestionOptions= props.itemsupdate.QuestionOptions
        cfitGroup.value.TypeTestID= props.itemsupdate.TypeTestID
        cfitGroup.value.Times= props.itemsupdate.Times
        cfitGroup.value.MaxAnswersOpt= props.itemsupdate.MaxAnswersOpt
        cfitGroup.value.Group= props.itemsupdate.Group

        avatarImageUrl.value = new URL(props.itemsupdate.QuestionImage, process.env.VUE_APP_API_URL_REAL2).href
        avatarFile.value = await convertUrlToFile(avatarImageUrl.value, props.itemsupdate.QuestionImage)
      }

      if(newVal){
        dialogAddGroup.value = true
      }
    });

    async function convertUrlToFile(imageUrl, fileName) {
        // Step 1: Fetch the image data
        const response = await fetch(imageUrl, { mode: 'no-cors' });
        const blob = await response.blob(); // Step 2: Create a Blob

        // Step 3: Create a File from the Blob
        var file = new File([blob], fileName, { type: blob.type });
        return file;
    }

    function modalClose(){
      dialogAddGroup.value = false
      emit("modalClose", true);
    }

    const cfitValidator = Yup.object().shape({
      fno: Yup.string().required().label("Grup"),
      fname: Yup.string().required().label("Nama"),
      fdurasi: Yup.string().required().label("Durasi"),
    });

    async function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)
      if(avatarFile.value != null)
        saveObj['QuestionImage'] = avatarFile.value

      saveObj['Group'] = saveObj['Group'].toUpperCase()

      if(props.isupdate){
        update(saveObj)
      }else{
        save(saveObj)
      }
    };

    async function save(saveObj){
      var responseCache = []
      await Services.PostData(ApiService2, `master/questions/`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    async function update(saveObj){
      var responseCache = []
      await Services.PutData(ApiService2,  `master/questions/${saveObj.QuestionID}`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    const saveCfit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(cfitGroup)
      }
    };

    function cancelModal(){
      cfitGroup.value.QuestionID= 0
      cfitGroup.value.SerialNumber= 0
      cfitGroup.value.QuestionNumber= 0
      cfitGroup.value.QuestionImage=  ""
      cfitGroup.value.QuestionDescription = ""
      cfitGroup.value.QuestionKey=""
      cfitGroup.value.QuestionOptions= "2"
      cfitGroup.value.TypeTestID = props.typetestid || ""
      cfitGroup.value.Times= 0
      cfitGroup.value.MaxAnswersOpt= ""
      cfitGroup.value.Group= ""

      removeImage()
      modalClose()
    }

    return {
      // data,
      cfitGroup,
      cfitValidator,
      saveCfit,
      submitButton,
      dialogAddGroup,
      cancelModal,
      modalClose,
      handleFileUpload,
      avatarImageUrl,
      removeImage,
    };
  },
});
