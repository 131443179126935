import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "svg-icon svg-icon-2" }
const _hoisted_6 = { class: "card-body pt-5" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "symbol symbol-40px me-1" }
const _hoisted_9 = { class: "text-dark fw-bold" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "text-muted fw-bold" }
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = { class: "d-flex my-3 ms-9" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  title: "",
  "data-bs-original-title": "Edit"
}
const _hoisted_18 = { class: "svg-icon svg-icon-3" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CreateCfitGroupComponent = _resolveComponent("CreateCfitGroupComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["card", _ctx.widgetClasses])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "card-label fw-bolder text-dark" }, "CFIT Grup Soal", -1)),
          _createElementVNode("span", _hoisted_3, "Total " + _toDisplayString(_ctx.cfitExampleCache.length) + " Group", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
            "data-kt-menu-trigger": "click",
            "data-kt-menu-placement": "bottom-end",
            "data-kt-menu-flip": "top-end",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalAdd(false, {})))
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cfitExampleCache, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "row"
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([{ 'mb-7': _ctx.cfitExampleCache.length - 1 !== index }, "d-flex align-items-center"])
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", {
                    class: _normalizeClass([`bg-light-${item.Color}`, "symbol-label"])
                  }, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(item.Group), 1)
                  ], 2)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.emitGroupId(item)),
                    class: "text-dark text-hover-primary fs-6 fw-bolder m-0 p-0",
                    style: {"cursor":"pointer"}
                  }, _toDisplayString(item.QuestionNumber), 9, _hoisted_12),
                  _createElementVNode("span", _hoisted_13, _toDisplayString(item.Times) + " Menit", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.onUpdate(item)),
                    class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                    "data-bs-toggle": "tooltip",
                    title: "",
                    "data-kt-customer-payment-method": "update",
                    "data-bs-original-title": "Update"
                  }, [
                    _createElementVNode("span", _hoisted_17, [
                      _createElementVNode("span", _hoisted_18, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ])
                  ], 8, _hoisted_16),
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.onDelete(item)),
                    class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                    "data-bs-toggle": "tooltip",
                    title: "",
                    "data-kt-customer-payment-method": "delete",
                    "data-bs-original-title": "Delete"
                  }, [
                    _createElementVNode("span", _hoisted_20, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ], 8, _hoisted_19)
                ])
              ])
            ], 2)
          ]))
        }), 128))
      ])
    ], 2),
    _createVNode(_component_CreateCfitGroupComponent, {
      typetestid: _ctx.TypeTestID,
      isopenmodal: _ctx.isopenmodal,
      onModalClose: _ctx.isModalClose,
      isupdate: _ctx.isUpdate,
      itemsupdate: _ctx.itemsUpdate,
      onIsneedrefresh: _ctx.isNeedRefresh
    }, null, 8, ["typetestid", "isopenmodal", "onModalClose", "isupdate", "itemsupdate", "onIsneedrefresh"])
  ], 64))
}