
import { defineComponent, onBeforeMount, ref, toRefs, watch } from "vue";
import CreateCfitQuestionComponent from "@/views/psy/cfit/component/CreateCfitQuestionComponent.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";

export default defineComponent({
  name: "cfit-question-component",
  components: {
    CreateCfitQuestionComponent,
  },
  props: {
    widgetClasses: String,
    cfitGroupId: String,
    cfitGroupType: String,
  },
  setup(props, {emit}) {
    const { cfitGroupId, cfitGroupType } = toRefs(props);
    var questionCache = ref([] as Array<any>)
    let isopenmodal = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})

    watch(cfitGroupId, (newVal, oldVal) => {
      getCfitQuestion(newVal)
    });

    onBeforeMount(() => {
      // console.log("sin")
      // getCfitQuestion()
    });

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    function onDelete(val){
      deleteCfit(val.QuestionID)
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function isNeedRefresh(val) {
      getCfitQuestion(val)
    }

    async function getCfitQuestion(val){
      questionCache.value = []
      await Services.GetData(ApiService2, `master/questions/masterid/options/group`, props.cfitGroupType + "/" +1+ "/" + val, response=>{
        questionCache.value = response.data
        questionCache.value = questionCache.value.orderBy(["QuestionNumber"], "ASC") 
        for (var i = 0; i < questionCache.value.length; i++) {
          questionCache.value[i].Color = getRandomColor()
          questionCache.value[i].avatarImageUrl = new URL(questionCache.value[i].QuestionImage, process.env.VUE_APP_API_URL_REAL2).href
          // avatarFile.value = await convertUrlToFile(avatarImageUrl.value, props.itemsupdate.QuestionImage)
          // questionCache.value[i].avatarImageUrl= `${ApiService.getUrl()}/IST/storage/${item.IST_Question}`,
        } 
        console.log("questionCache.value", questionCache.value)
      }, err =>{
        err
      })
    }

    async function deleteCfit(QuestionID){
      await Services.DeleteData(ApiService2, `master/questions/${QuestionID}`, response=>{
        getCfitQuestion(props.cfitGroupId)
      }, err =>{
        err
      })
    }

    function openModalAdd(isupdate, itemsupdate){
      isUpdate.value = isupdate
      itemsUpdate.value = itemsupdate
      isopenmodal.value = true

      // if(props.cfitGroupId == undefined 
      //     || props.cfitGroupId == null 
      //     || props.cfitGroupId == ""){
      //       isopenmodal.value = false
      // }else{
      //   isUpdate.value = isupdate
      //   itemsUpdate.value = itemsupdate
      //   isopenmodal.value = true
      // }
    }

    return {
      questionCache,
      getRandomColor,
      openModalAdd,
      isopenmodal,
      onDelete,
      isUpdate,
      itemsUpdate,
      isNeedRefresh,
      isModalClose,
      onUpdate,
    };
  },
});
