
import { defineComponent, onBeforeMount, ref, toRefs, watch } from "vue";
import CreateCfitGroupComponent from "@/views/psy/cfit/component/CreateCfitGroupComponent.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";

export default defineComponent({
  name: "cfit-group-component",
  components: {
    CreateCfitGroupComponent,
  },
  props: {
    TypeTestID: String
  },
  setup(props, {emit}) {
    let isopenmodal = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})
    var cfitExampleCache = ref([] as Array<any>)

    onBeforeMount(() => {
      getCfit()
    });

    async function getCfit(){
      cfitExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions/masterid/options`, props.TypeTestID+ "/" +2, response=>{
        cfitExampleCache.value = response.data
        cfitExampleCache.value = cfitExampleCache.value.orderBy(["Group"], "ASC")
        for (var i = 0; i < cfitExampleCache.value.length; i++) {
          cfitExampleCache.value[i].Color = getRandomColor()
        } 
        // console.log("cfitExampleCache.value", cfitExampleCache.value)
      }, err =>{
        err
      })
    }

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function openModalAdd(isupdate, itemsupdate){
      isUpdate.value = isupdate
      itemsUpdate.value = itemsupdate
      isopenmodal.value = true
    }

    function emitGroupId(val){
      emit("clickDraw", val);
    }

    function isNeedRefresh(val) {
      getCfit()
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    function onDelete(val){
      deleteCfit(val.QuestionID)
    }

    async function deleteCfit(QuestionID){
      await Services.DeleteData(ApiService2, `master/questions/${QuestionID}`, response=>{
        getCfit()
      }, err =>{
        err
      })
    }

    return {
      getRandomColor,
      emitGroupId,
      onDelete,
      onUpdate,
      isUpdate,
      itemsUpdate,
      isopenmodal,
      openModalAdd,
      isNeedRefresh,
      isModalClose,

      cfitExampleCache
    };
  },
});
